import './Header.css'
import React, { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <img alt="logo" src="/img/logo.png" className="logo" />
      <nav className="navbar">
        <a href="#hero">Inicio</a>
        <a href="#destination">Destino</a>
        <a href="#boat">Lancha</a>
        <a href="#reserve">Reservar</a>
      </nav>
      <div className="hamburger-menu" onClick={toggleMenu}>
        &#9776;
      </div>

      {/* Menu mobile */}
      {isMenuOpen && (
        <div className="mobile-menu">
          <a href="#hero">Inicio</a>
          <a href="#destination">Destino</a>
          <a href="#boat">Lancha</a>
          <a href="#reserve">Reservar</a>
        </div>
      )}
    </header>
  );
};

export default Header;
