import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './Carousel.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default function Carousel() {
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide><img alt="boat" className="img-boat" src="/img/boat/boat.png" /></SwiperSlide>
        <SwiperSlide><img alt="boat" className="img-boat" src="/img/boat/boat2.png" /></SwiperSlide>
        <SwiperSlide><img alt="boat" className="img-boat" src="/img/boat/boat3.png" /></SwiperSlide>
        <SwiperSlide><img alt="boat" className="img-boat" src="/img/boat/boat4.jpg" /></SwiperSlide>
      </Swiper>
    </>
  );
}
