import './App.css';
import Header from './components/Header/Header.js';
import Hero from './components/Hero/Hero.js';
import Destination from './components/Destination/Destination.js';
import Boat from './components/Boat/Boat.js';
import Why from './components/Why/Why.js';
import Reserve from './components/Reserve/Reserve.js';
import Footer from './components/Footer/Footer.js';

function App() {
  return (
    <>
      <div className='container'>
        <Header />
        <main>
          <Hero />
          <div className='destination-boat'>
            <Destination />
            <Boat />
            <Why />
          </div>
          <Reserve />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default App;
