import './Reserve.css'


function Reserve(){
    return(
        <section className="reserve" id="reserve">
            <div>
                <img alt="reserve" src="./img/icons/beach.png"></img>
            </div>
            <div className='text-center'>
                <h1>Explore Angra dos Reis com a Passeios Angra!</h1>
            </div>
            <div>
                <a href="https://api.whatsapp.com/send?phone=5524999124530" target="_blank" rel="noopener noreferrer" className='btn-default'>RESERVAR MEU PASSEIO</a>
                <span>Faça seu orçamento!</span>
            </div>
        </section>
    )
}

export default Reserve;