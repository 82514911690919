import './Why.css'


function Why(){
    return(
        <section className="why">
            <div>
                <h1> Por que a Passeios Angra ?</h1>
            </div>
            <div>
                <div className='card-why'>
                    <div className='icon'>
                        <img alt='cataguases' src="/img/icons/sofa.png"></img>
                    </div>
                    <h2 className='title'>Conforto</h2>
                    <span className='body'>Desfrute de um passeio relaxante em um ambiente acolhedor. Você poderá curtir cada momento com tranquilidade enquanto navega pelas águas de Angra dos Reis.</span>
                </div>
                <div className='card-why'>
                    <div className='icon'>
                        <img alt='cataguases' src="/img/icons/lifebuoy.png"></img>
                    </div>
                    <h2 className='title'>Segurança</h2>
                    <span className='body'>Cada passeio é cuidadosamente planejado com foco na segurança, com equipamentos de ponta e atenção dedicada para que você curta o passeio com tranquilidade.</span>
                </div>
                <div className='card-why'>
                    <div className='icon'>
                        <img alt='cataguases' src="/img/icons/like.png"></img>
                    </div>
                    <h2 className='title'>Reserva Fácil e Flexível</h2>
                    <span className='body'>Nossa reserva é rápida e descomplicada, com opções flexíveis de horários para se ajustar à sua disponibilidade.</span>
                </div>
                <div className='card-why'>
                    <div className='icon'>
                        <img alt='cataguases' src="/img/icons/exclusive.png"></img>
                    </div>
                    <h2 className='title'>Privacidade</h2>
                    <span className='body'>Tenha uma experiência exclusiva e personalizada, com total privacidade para você e seus convidados.</span>
                </div>
            </div>
        </section>
    )
}

export default Why;