// Destination.js
import './Destination.css';
import Carousel from "./Carousel/Carousel.js";
import Exclusive from '../Exclusive/Exclusive.js';
function Destination() {
    const destinations = [
        {
            img: "/img/destination/ilha-cataguases.png",
            title: "Ilha Cataguases",
            alt: 'cataguases'
        },
        {
            img: "/img/destination/ilha-botinas.png",
            title: "Ilha das Botinas",
            alt: 'botinas'
        },
        {
            img: "/img/destination/ilha-gipoia.png",
            title: "Ilha da Gipóia",
            alt: 'gipoia'
        },
        {
            img: "/img/destination/praia-laboratorio.png",
            title: "Praia do Laboratório",
            alt: 'laboratorio'
        },
        {
            img: "/img/destination/praia-dentista.jpeg",
            title: "Praia do Dentista",
            alt: 'laboratorio'
        },
        {
            img: "/img/destination/praia-lagoa-verde.jpg",
            title: "Lagoa Verde",
            alt: 'laboratorio'
        },
        {
            img: "/img/destination/praia-lagoa-azul.jpeg",
            title: "Lagoa Azul",
            alt: 'laboratorio'
        },
        {
            img: "/img/destination/praia-flechas.webp",
            title: "Praia das Flechas",
            alt: 'flechas'
        },
        {
            img: "/img/destination/praia-saco.jpg",
            title: "Saco do Céu",
            alt: 'saco'
        },
    ];

    return (
        <section className='destination' id="destination">
            <div>
                <div className='header'>
                    <h1>Destinos</h1>
                    <h2>Veja algumas de muitas opções para seu próximo passeio exclusivo e personalizado.</h2>
                </div>
                <Carousel destinations={destinations} />
                <Exclusive />
            </div>
        </section>
    );
}

export default Destination;
