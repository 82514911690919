import './Boat.css'
import Carousel from './Carousel/Carousel.js'

function Boat(){
    return(
        <section id="boat">
            <div>
                <div>
                    <h1>A Lancha</h1>
                    <h2>Confortável, segura e perfeita para explorar as belezas de Angra dos Reis.</h2>
                </div>
                <div>
                    <div className='about-boat'>
                        <h2>Lancha de 23 pés, ideal para passeios em família ou com amigos.</h2>
                        <h2>Coletes salva-vidas e todos os equipamentos de segurança exigidos para navegação.</h2>
                        <h2>Sistema de som ambiente.</h2>
                    </div>
                    <Carousel />
                </div>
            </div>
        </section>
    )
}

export default Boat;