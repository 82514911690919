// Carousel.js
import React from "react";

const Carousel = ({ destinations }) => {
  return (
      <div className="cards-destination">
          {/* <Slider {...settings}> */}
              {destinations.map((destination, index) => (
                  <div className="card-destination" key={index}>
                      <img alt={destination.alt} src={destination.img} />
                      <h2 className='title'>{destination.title}</h2>
                  </div>
              ))}
          {/* </Slider> */}
      </div>
    );
};

export default Carousel;
