import './Hero.css'


function Hero(){
    return(
        <section id="hero">
            <div>
                <h1>Passeios Angra</h1>
                <h2>Desde 2008</h2>
            </div>
        </section>
    )
}

export default Hero;