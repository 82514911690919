import React from "react";
import './Exclusive.css';
export default function Exclusive (){

    return(
        <>
            <section id="exclusive">
                <div>
                    <h1>Pôr do Sol Inesquecível na Ilha das Botinas</h1>
                    <h2>Oferecemos um exclusivo passeio noturno até a Ilha das Botinas, onde você terá a chance de presenciar o pôr do sol mais espetacular da sua vida. </h2>
                    <div className="card-destination exclusive">
                        <img alt="exclusivo" src="/img/destination/passeio-exclusivo.jpg"></img>
                    </div>
                </div>
            </section>
        </>
    )

}