import './Footer.css'


function Footer(){
    return(
        <footer>
            <div>
                <div className='div-local'>
                    <h2>Angra dos Reis. RJ</h2>
                    <h2>-Brasil</h2>
                </div>
                <div>
                    <img alt="reserve" src="./img/logo-footer.png"></img>
                </div>
                <div className='div-contact'>
                    <div className="icons"> 
                        <a href="https://www.facebook.com/passeiosangra2" target="_blank" rel="noopener noreferrer"><img alt="facebook" src="/img/icons/facebook.png"></img></a>
                        <a href="https://www.instagram.com/passeiosangra.oficial/" target="_blank" rel="noopener noreferrer"><img alt="instagram" src="/img/icons/instagram.png"></img></a>
                        <a href="https://api.whatsapp.com/send?phone=5524999124530" target="_blank" rel="noopener noreferrer"><img alt="whatsapp" src="/img/icons/whatsapp.png"></img></a>
                    </div>
                    <div className='div-email'>
                        <img alt='email' src="/img/icons/mail.png"></img>
                        <a href="mailto:seuemail@exemplo.com">contatospasseiosangra@gmail.com</a>
                    </div>
                </div>
            </div>
            <div>
                <span>Todos os direitos reservados</span>
            </div>
        </footer>
    )
}

export default Footer;